import React from "react";
import PropTypes from "prop-types";
import { Button, AcceptButton, ContinueButton } from "../input/Button";
import styles from "./AvatarSettingsContent.scss";
import { TextInputField } from "../input/TextInputField";
import { Column } from "../layout/Column";
import { FormattedMessage } from "react-intl";

export function AvatarSettingsContent({
  displayName,
  displayNameInputRef,
  disableDisplayNameInput,
  onChangeDisplayName,
  avatarPreview,
  displayNamePattern,
  onChangeAvatar,
  ...rest
}) {
  const store = window.APP.store;
  return (
    <Column as="form" className={styles.content} {...rest}>
      <TextInputField
        disabled={true}
        label=""
        value={displayName}
        pattern={displayNamePattern}
        spellCheck="false"
        required
        onChange={onChangeDisplayName}
        description=""
        ref={displayNameInputRef}
        className={styles.nameInput}
      />
      <div className={styles.avatarPreviewContainer}>
        {avatarPreview || <div />}
        { store.state.credentials.wallet &&
        <Button type="button" preset="basic" onClick={onChangeAvatar}>
          <FormattedMessage id="avatar-settings-content.change-avatar-button" defaultMessage="Change Avatar" />
        </Button>}
      </div>
      {store.state.credentials.wallet ? <AcceptButton preset="accept" type="submit" /> : <ContinueButton preset="accept" type="submit" /> }
    </Column>
  );
}

AvatarSettingsContent.propTypes = {
  className: PropTypes.string,
  displayName: PropTypes.string,
  displayNameInputRef: PropTypes.func,
  disableDisplayNameInput: PropTypes.bool,
  displayNamePattern: PropTypes.string,
  onChangeDisplayName: PropTypes.func,
  avatarPreview: PropTypes.node,
  onChangeAvatar: PropTypes.func
};
