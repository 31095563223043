import React from "react";
import PropTypes from "prop-types";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { LoadingScreenLayout } from "../layout/LoadingScreenLayout";
import { Button } from "../input/Button";
import { SignInButton } from "../home/SignInButton";
import { ReactComponent as NearIcon } from "../misc/Near.svg";

export function GatedRoomScreen({ gateInfo, logoSrc }) {

    const onConnectWallet = () => {
        document.location = `/?wallet_sign_in&sign_in_destination=hub&sign_in_destination_url=${encodeURIComponent(document.location.toString())}`;
    }

  const info = <>
    This room is NFT gated.<br />
    {gateInfo?.image ? <><img src={gateInfo.image} /><br /></> : ''}
    {gateInfo?.description ? <>{gateInfo.description}<br /></> : ''}
    Visit <a href={gateInfo.url}>{gateInfo.url}</a>
    {
        (!window.nearWallet.isSignedIn()) && 
        <>
            - OR -<br />
            <Button preset="accent6" onClick={onConnectWallet}>
              <NearIcon width="30" height="30" fill="#fff" style={{marginRight: "0"}} />
              <span>
                <FormattedMessage id="room-entry-modal.wallet-button" defaultMessage="Connect Wallet" />
              </span>
            </Button>
            
        </>
    }    
    <a href="/" style={{marginLeft: 30}}>
        <Button preset="accent4">              
            <span>
            <FormattedMessage id="room-entry-modal.home-button" defaultMessage="Nearhub Homepage" />
            </span>
        </Button>
    </a>
  </>

  return <LoadingScreenLayout center={info} logoSrc={logoSrc} columnStyle={{maxWidth: 450}} />;
}

GatedRoomScreen.propTypes = {
  gateInfo: PropTypes.object.isRequired,
  logoSrc: PropTypes.string
};
