import React from "react";
import PropTypes from "prop-types";
import configs from "../../utils/configs";
import { GatedRoomScreen } from "./GatedRoomScreen";

export function GatedRoomScreenContainer({ gateInfo }) {
  return (
    <GatedRoomScreen
      logoSrc={configs.image("logo")}
      gateInfo={gateInfo}
    />
  );
}

GatedRoomScreenContainer.propTypes = {
  gateInfo: PropTypes.string.isRequired
};
