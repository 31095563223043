import React from "react";
import PropTypes from "prop-types";
import { NearPhonePopover, NearPhoneButton } from "./NearPhonePopover";

export function NearPhonePopoverContainer({ ...rest }) {
  return <NearPhonePopover {...rest} />;
}

export function NearPhoneButtonContainer({ ...rest }) {
  return <NearPhoneButton {...rest} />;
}
