import React, { useState, useEffect, useRef } from "react";
import styles from "./NearPhonePopover.scss";
import { ToolbarButton } from "../input/ToolbarButton";
import { Button } from "../input/Button";
import { ReactComponent as EnterIcon } from "../icons/Enter.svg";
import { ReactComponent as PhoneIcon } from "../icons/Phone.svg";
import { ReactComponent as NearIcon } from "../misc/Near.svg";
import { FormattedMessage, defineMessage, useIntl } from "react-intl";
import { createPortal } from "react-dom";
import { useMicrophoneStatus } from "./useMicrophoneStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as NearLogo } from "../misc/Near.svg";
import { NearPulse } from "../misc/NearPulse"
import { ReactComponent as WaveIcon } from "../icons/HandWave.svg";
import Big from 'big.js';
import {
  faHome,
  faExpand,
  faCompress,
  faPhone,
  faMicrophone, 
  faMicrophoneSlash,
  faCog,
  faTimes
} from "@fortawesome/free-solid-svg-icons";

const nearPhonePopoverTitle = defineMessage({
  id: "nearphone-popover.title",
  defaultMessage: "Phone"
});

export function NearPhoneButton({ onClick, ...rest }) {
  const intl = useIntl();
  const title = intl.formatMessage(nearPhonePopoverTitle);
  return (
    <ToolbarButton
      onClick={onClick}
      icon={
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 288">
          <g id="Layer_1" data-name="Layer 1">
            <path d="M187.58,79.81l-30.1,44.69a3.2,3.2,0,0,0,4.75,4.2L191.86,103a1.2,1.2,0,0,1,2,.91v80.46a1.2,1.2,0,0,1-2.12.77L102.18,77.93A15.35,15.35,0,0,0,90.47,72.5H87.34A15.34,15.34,0,0,0,72,87.84V201.16A15.34,15.34,0,0,0,87.34,216.5h0a15.35,15.35,0,0,0,13.08-7.31l30.1-44.69a3.2,3.2,0,0,0-4.75-4.2L96.14,186a1.2,1.2,0,0,1-2-.91V104.61a1.2,1.2,0,0,1,2.12-.77l89.55,107.23a15.35,15.35,0,0,0,11.71,5.43h3.13A15.34,15.34,0,0,0,216,201.16V87.84A15.34,15.34,0,0,0,200.66,72.5h0A15.35,15.35,0,0,0,187.58,79.81Z" />
          </g>
        </svg>
      }
      label={title}
      {...rest}
    />
  );
}

export function NearPhonePopover({ hub, store, scene, state, closeHandler, ...rest }) {
  const { isMicMuted, toggleMute, isMicEnabled } = useMicrophoneStatus(scene);  
  const intl = useIntl();
  const contentRef = useRef();
  const guestbookRef = useRef();
  const popoverRef = useRef();
  const homeRef = useRef();
  const jitsiRef = useRef();
  const jitsiRefInner = useRef();
  const actionSheetRef = useRef();
  const [openDapp, setOpenDapp] = useState(null);
  const [isFullScreen, setFullScreen] = useState(false);
  const [isJitsiActive, setJitsiActive] = useState(false);
  const [isJitsiLoading, setJitsiLoading] = useState(true);
  //const [homepageJSON, setHomepageJSON] = useState(null);
  const homepageJSON = state.nearPhoneData;
  const [isHomeLoading, setHomeLoading] = useState(false);
  const [jistsi, setJitsi] = useState(null);
  const [actionSheetActive, setActionSheetActive] = useState(false);
  const [jitsiLink, setJitsiLink] = useState('');
  const [guestbookEntries, setGuestbookEntries] = useState([]);
  const [guestbookEntry, setGuestbookEntry] = useState('');
  const [isSigning, setIsSigning] = useState(false);

  const [originalVoiceVolume, setOriginalVoiceVolume] = useState(APP.store.state.preferences.globalVoiceVolume);
  //const [originalMediaVolume, setOriginalMediaVolume] = useState(APP.store.state.preferences.globalMediaVolume);
  const [originalMuteStatus, setOriginalMuteStatus] = useState(isMicMuted);

  const onDrag = evt => {
    evt.preventDefault();
    return false;
  };

  const onDrop = evt => {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const offset = evt.dataTransfer.getData("text/plain").split(",");
    const dm = popoverRef.current;

    let left = evt.clientX + parseInt(offset[0], 10);
    let top = evt.clientY + parseInt(offset[1], 10);

    if (left < 0) {
      left = 0;
    } else if (left + dm.offsetWidth > vw) {
      left = vw - dm.offsetWidth;
    }

    if (top < 0) {
      top = 0;
    } else if (top + dm.offsetHeight > vh) {
      top = vh - dm.offsetHeight;
    }
    dm.style.left = left + "px";
    dm.style.top = top + "px";
    evt.preventDefault();
    return false;
  };

  const onDragStart = evt => {
    if (isFullScreen) return false;
    evt.target.style.opacity = "0.4";

    const style = window.getComputedStyle(evt.target, null);
    evt.dataTransfer.setData(
      "text/plain",
      parseInt(style.getPropertyValue("left"), 10) -
        evt.clientX +
        "," +
        (parseInt(style.getPropertyValue("top"), 10) - evt.clientY)
    );

    document.body.addEventListener("dragover", onDrag, false);
    document.body.addEventListener("drop", onDrop, false);
  };

  const onDragEnd = evt => {
    evt.target.style.opacity = "1";
    document.body.removeEventListener("dragover", onDrag);
    document.body.removeEventListener("drop", onDrop);
  };

  const toggleFullScreen = evt => {
    const isFullscreenAvailable = document.fullscreenEnabled;

    if (isFullScreen) {
      if (isFullscreenAvailable) {
        document.exitFullscreen();
      }
      setFullScreen(false);
    } else {
      if (isFullscreenAvailable) {
        popoverRef.current.requestFullscreen().catch(err => {
          console.log(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
        });
        setFullScreen(true);
      }
    }
  };

  const showJitsi = (evt, type) => {
    setActionSheetActive(false);
    if (!isJitsiActive) {
      APP.store.update({
        preferences: {
          globalVoiceVolume: 0/*,
          globalMediaVolume: 0*/
        }
      });

      if (!isMicMuted) {
        toggleMute();
      }

      setJitsiLoading(true);

      let options = {        
        parentNode: jitsiRefInner.current,
        interfaceConfigOverwrite: {
          MOBILE_APP_PROMO: false
        },
        configOverwrite: {
          disableModeratorIndicator: true
        },
        userInfo: {
          displayName: store.state.profile.displayName
        }
      };
      if (type && type == 'room') {
        options.roomName = hub.name;
        setJitsiLink('https://talk.nearhub.online/'+hub.name);
      } else {
        options.roomName = store.state.profile.displayName;
        setJitsiLink('https://talk.nearhub.online/'+store.state.profile.displayName);
      }

      const api = new JitsiMeetExternalAPI("talk.nearhub.online", options);
      api.addListener("dataChannelOpened", () => {
        setJitsiLoading(false);
      });
      api.addListener("readyToClose", () => {
        APP.store.update({
          preferences: {
            globalVoiceVolume: originalVoiceVolume/*,
            globalMediaVolume: originalMediaVolume*/
          }
        });
        
        if (!originalMuteStatus) {
          toggleMute();
        }

        setJitsiActive(false);
        api.dispose();
        setJitsi(null);

        contentRef.current.style.display = "none";
        guestbookRef.current.style.display = "none";
        jitsiRef.current.style.display = "none";
        homeRef.current.style.display = "block";
      });
      setJitsi(api);
      setJitsiActive(true);
    }
    contentRef.current.style.display = "none";
    guestbookRef.current.style.display = "none";
    homeRef.current.style.display = "none";
    jitsiRef.current.style.display = "block";
  };

  const toggleCallActionSheet = evt => {
    setActionSheetActive(!actionSheetActive);
  }

  const dappClicked = (e, ind) => {
    setOpenDapp(ind);
    //e.target.scrollIntoView(true);
    homeRef.current.scroll({top:0,behavior:'smooth'})
    e.stopPropagation();
  };

  const clearSelected = () => {
    setOpenDapp(null);
  };

  const showHome = evt => {
    setOpenDapp(null);
    contentRef.current.style.display = "none";
    guestbookRef.current.style.display = "none";
    homeRef.current.style.display = "block";
    jitsiRef.current.style.display = "none";
  };

  const showGuestbook = evt => {    
    contentRef.current.style.display = "none";
    guestbookRef.current.style.display = "block";
    homeRef.current.style.display = "none";
    jitsiRef.current.style.display = "none";
  };

  /*
  const getHomepageData = () => {
    setHomeLoading(true);
    fetch("https://api.npoint.io/dfe6610df148414ec38e", {
    //fetch("https://raw.githubusercontent.com/NEARHub-online/NEARDapps/main/neardapp.json", {
    //fetch("https://xpressout.com/dfe6610df148414ec38e.json", {
    //fetch("https://hubs-nearhub-online-proxy.nearhub.workers.dev/files/6e5d9ee8-07d6-4e35-b645-27354b7d526e.json?token=a2966f18950ee9f06ca3a69ae6adf0ed", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    })
      .then(function(response) {
        setHomeLoading(false);
        return response.json();
      })
      .then(function(jsondata) {
        setHomepageJSON(jsondata);
      });
  };
  */

  const copyLink = () => {
    navigator.clipboard.writeText(jitsiLink);
    setJitsiLink('copied');
    setTimeout(() => { setJitsiLink(jitsiLink); },2000);
  }

  useEffect(() => {
    document.addEventListener("fullscreenchange", () => {
      if (document.fullscreenElement) {
        setFullScreen(true);
      } else {
        setFullScreen(false);
      }
    });
    
    window.nearContract.getMessagesId({id:hub.hub_id}).then(data => setGuestbookEntries(data));

  }, []);

  const openURL = (evt, url) => {
    evt.preventDefault();

    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', url);
    iframe.style.height = 'calc(100% - 16px)';    

    const titleEle = document.createElement('div');
    titleEle.style.textAlign = 'center';
    titleEle.style.paddingBottom = '6px';
    titleEle.style.color = '#ddd';
    titleEle.style.fontSize = '12px';
    titleEle.innerHTML = url;

    contentRef.current.replaceChildren();    
    contentRef.current.appendChild(titleEle);
    contentRef.current.appendChild(iframe);
    contentRef.current.style.display = "block";
    homeRef.current.style.display = "none";
    jitsiRef.current.style.display = "none";
    return false;
  }

  const addGuestbookEntry = (e) => {
    if (window.nearWallet.getAccountId() != '') {      
      setIsSigning(true);
      window.nearContract.addMessageId(
        { 
          text: guestbookEntry, 
          id: hub.hub_id
        },
        Big(3).times(10 ** 13).toFixed(), // boatload of gas
        Big('0').times(10 ** 24).toFixed() // donation
      ).then(() => {
        setIsSigning(false);
        setGuestbookEntry('');
        window.nearContract.getMessagesId({id:hub.hub_id}).then(data => setGuestbookEntries(data));
      }); 
    }
  }

  const timeSince = (date) => {

    var seconds = Math.floor((new Date() - date) / 1000);
  
    var interval = seconds / 31536000;
  
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }

  return (
    <>
      {state.nearPhoneVisible
        ? createPortal(
            <div
              draggable="true"
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
              onDrag={onDrag}
              className={`${styles.nearPhonePopover} ${isFullScreen ? styles.fullscreen : ""}`}
              ref={popoverRef}
            >              
              <div ref={homeRef} className="home" onClick={clearSelected}>
                {
                  isHomeLoading
                    ? <NearPulse />
                    : <>
                      <NearLogo />
                      <h3 className={`${openDapp != null ? 'dappopen' : '' }`}>Experiences</h3>
                      <ul className={`${openDapp != null ? 'dappopen' : '' } rooms`}>
                        {homepageJSON &&
                          homepageJSON.filter(ele => ele.EXPERIENCES && Array.isArray(ele.EXPERIENCES) && ele.EXPERIENCES.length).sort((a,b) => a.featured < b.featured ? 1 : -1).map((ele, ind) => {
                            return (
                              <li key={''+ind} onClick={e => dappClicked(e, ''+ind)} className={openDapp === ''+ind ? "open" : ""}>
                                <div style={{height: "60px"}}>
                                  <img src={'data:'+ele.image.mime+';base64,'+ele.image.data} style={{ maxWidth: "60px", maxHeight: "60px"}} />
                                </div>
                                <span style={{fontSize: "14px", fontWeight: "500"}}>{ele.title}</span>
                                <p>{ele.description}</p>
                                <div>
                                  { ele.EXPERIENCES && ele.EXPERIENCES.map( (ele, ind) => {
                                      return <a key={'exp'+ind} href={ele.link}>
                                        <Button preset="accent4">
                                          <EnterIcon />
                                          <span>{ele.title}</span>
                                        </Button>
                                      </a>
                                    })
                                  }
                                  { ele.website &&
                                    <a href={ele.website} target="_new">
                                      <Button preset="accent6" onClick={(e) => openURL(e,ele.website)}>
                                        <EnterIcon />
                                        <span>
                                          <FormattedMessage
                                            id="near-phone.visit-website-button"
                                            defaultMessage="Visit Website"
                                          />
                                        </span>
                                      </Button>
                                    </a>
                                  }
                                </div>
                              </li>
                            );
                          })
                        }
                      </ul>
                      <h3 className={`${openDapp != null ? 'dappopen' : '' }`}>Phone</h3>
                      <ul className={openDapp != null ? 'dappopen' : '' }>
                        {homepageJSON &&
                        homepageJSON.map(ele => ele.category).reduce((prevVal, currVal) => { return [...new Set(([...prevVal, ...currVal]))]; }).map((catele, catind) => {
                          return (
                            <li key={'cat'+catind} 
                                onClick={e => dappClicked(e, 'cat'+catind)} 
                                className={`${openDapp === 'cat'+catind || (openDapp && openDapp.startsWith('cat'+catind+'-')) ? "open" : ""} ${ openDapp && openDapp.startsWith('cat'+catind+'-') && openDapp.includes('-app') ? 'dappopen' : '' } group`}>
                              <div>
                                <span>
                                  { homepageJSON &&
                                    homepageJSON.filter(ele => ele.category && ele.category.find(ele => ele == catele)).sort((a,b) => a.featured < b.featured ? 1 : -1).slice(0,4).map((ele, ind) => {
                                      return (<img key={'img'+ind} src={'data:'+ele.image.mime+';base64,'+ele.image.data} />);
                                    })
                                  }
                                </span>
                              </div>
                              <span>{catele}</span>
                              <ul>
                                {homepageJSON &&
                                  homepageJSON.filter(ele => ele.category && ele.category.find(ele => ele == catele)).sort((a,b) => a.featured < b.featured ? 1 : -1).map((ele, ind) => {
                                    return (
                                      <li key={'cat'+catind+'-app'+ind} 
                                          onClick={e => dappClicked(e, 'cat'+catind+'-app'+ind)} 
                                          className={openDapp === 'cat'+catind+'-app'+ind ? "open" : ""}>
                                        <div>
                                          <img src={'data:'+ele.image.mime+';base64,'+ele.image.data} />
                                        </div>
                                        <span>{ele.title}</span>
                                        <p>{ele.description}</p>
                                        <div>
                                          { ele.EXPERIENCES && Array.isArray(ele.EXPERIENCES) && ele.EXPERIENCES.map( (ele, ind) => {
                                              return <a key={'exp'+ind} href={ele.link}>
                                                <Button preset="accent4">
                                                  <EnterIcon />
                                                  <span>{ele.title}</span>
                                                </Button>
                                              </a>
                                            })
                                          }
                                          { ele.website && 
                                            <a href={ele.website} target="_new">
                                              <Button preset="accent6" onClick={(e) => openURL(e,ele.website)}>
                                                <EnterIcon />
                                                <span>
                                                  <FormattedMessage
                                                    id="near-phone.visit-website-button"
                                                    defaultMessage="Visit Website"
                                                  />
                                                </span>
                                              </Button>
                                            </a>
                                          }
                                        </div>
                                      </li>
                                    );
                                  })
                                }
                              </ul>
                            </li>
                          );
                        })
                        }
                      </ul>
                    </>
                }
              </div>
              <div ref={guestbookRef} className="guestbook">
                <textarea maxLength="200" onChange={(e) => { setGuestbookEntry(e.target.value) } } value={guestbookEntry} />
                <button type="button" disabled={window.nearWallet.getAccountId() == '' || isSigning ? true : ''} style={window.nearWallet.getAccountId() == '' ? {opacity: '0.4'} : {}} onClick={addGuestbookEntry}>{isSigning?'Signing':'Sign Guestbook'}</button>
                {window.nearWallet.getAccountId() == '' && <div style={{textAlign: 'center', fontSize: '12px', fontWeight: '500'}}>Sign in to Near to sign guestbook</div>}
                <ul>
                  {
                    guestbookEntries && guestbookEntries.filter(entry => entry.banned == false).sort((a,b) => parseInt(a.created) < parseInt(b.created)).map((entry, ind) => {
                      const signedOn = parseInt(entry.created) + 1640995200;
                      return <li key={ind} style={{ marginBottom: '5px', paddingBottom: '5px', borderBottom: '1px solid silver', lineHeight: '1.3em' }}>
                        {entry.text} <div style={{ fontSize: '11px', marginTop: '5px' }}><i>by {entry.sender} - { timeSince(new Date(signedOn*1000)) }</i></div>
                      </li>
                    })
                  }
                </ul>
              </div>
              <div ref={contentRef} className="content" />
              <div ref={jitsiRef} className={`content ${isJitsiLoading ? "loading" : ""}`}>
                <div className="jitiLink" onClick={copyLink}>{jitsiLink}</div>
                <div ref={jitsiRefInner} className="jitsiInner"></div>
              </div>
              <ul ref={actionSheetRef} className="action-sheet" style={actionSheetActive ? { display: "block" } : {}}>
                <li onClick={(e) => showJitsi(e,'room')}>Join Room Call</li>
                <li onClick={(e) => showJitsi(e,'private')}>Make Private Call</li>
              </ul>
              <div className="actions">
                <ul>
                  {
                  <li title="Guestbook" onClick={showGuestbook}>
                   <WaveIcon width='32px' height='32px' title="Guestbook" color="currentColor" />
                  </li>
                  }
                  {
                   /*
                  <li onClick={toggleMute} title="Toggle Room Audio">
                    {isMuted ? (
                      <FontAwesomeIcon icon={faMicrophoneSlash} style={{ color: "#a10000" }} />
                    ) : (
                      <FontAwesomeIcon icon={faMicrophone} style={{ color: "#00d100" }} />
                    )}
                  </li>                  
                  */
                  }
                  <li title="Make Call" onClick={isJitsiActive ? showJitsi : toggleCallActionSheet}>
                    <FontAwesomeIcon icon={faPhone} style={isJitsiActive ? { color: "#00d100" } : {}} />
                  </li>
                  {/*
                    <li>
                      <FontAwesomeIcon icon={faHome} />
                    </li>
                    */}
                  <li title="Phone" onClick={showHome}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 288">
                      <path
                        fill="currentColor"
                        d="M187.58,79.81l-30.1,44.69a3.2,3.2,0,0,0,4.75,4.2L191.86,103a1.2,1.2,0,0,1,2,.91v80.46a1.2,1.2,0,0,1-2.12.77L102.18,77.93A15.35,15.35,0,0,0,90.47,72.5H87.34A15.34,15.34,0,0,0,72,87.84V201.16A15.34,15.34,0,0,0,87.34,216.5h0a15.35,15.35,0,0,0,13.08-7.31l30.1-44.69a3.2,3.2,0,0,0-4.75-4.2L96.14,186a1.2,1.2,0,0,1-2-.91V104.61a1.2,1.2,0,0,1,2.12-.77l89.55,107.23a15.35,15.35,0,0,0,11.71,5.43h3.13A15.34,15.34,0,0,0,216,201.16V87.84A15.34,15.34,0,0,0,200.66,72.5h0A15.35,15.35,0,0,0,187.58,79.81Z"
                      />
                    </svg>
                  </li>
                  {
                    /*
                    <li>
                      <FontAwesomeIcon icon={faCog} />
                    </li>
                    */
                  }
                  <li onClick={toggleFullScreen} title="Toggle Fullscreen">
                    {isFullScreen ? <FontAwesomeIcon icon={faCompress} /> : <FontAwesomeIcon icon={faExpand} />}
                  </li>
                  <li title="Close" onClick={() => {
                        APP.store.update({
                          preferences: {
                            globalVoiceVolume: originalVoiceVolume/*,
                            globalMediaVolume: originalMediaVolume*/
                          }
                        });
                        closeHandler();
                      }
                    }>
                    <FontAwesomeIcon icon={faTimes} />
                  </li>
                </ul>                
              </div>
            </div>,
            document.body
          )
        : ""}
    </>
  );
}
